@media screen and (max-width: 768px) {
    .hide-mobile {
        display: none;
    }
}

@media screen and (max-width: 1280px) {
    .hide-browser {
        display: none;
    }
}

.sidebar {
    width: 280px;
    max-width: 280px;
    display: flex;
    height: 100vh;
    max-height: 100vh;
    flex-direction: column;
    z-index: 100;
    padding: 48px 0px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .3);
    background-color: #363740;
    overflow-y: scroll;
}

.menu-item {
    display: flex;
    font-size: 18px;
    cursor: pointer;
    color: #aaaaaa;
    border: 1px solid transparent;
    padding: 6px 16px;
}

.menu-item:hover {
    border: 1px solid #aaaaaa;
    background-color: #464750;
}

.menu-item-selected {
    display: flex;
    font-size: 18px;
    cursor: pointer;
    color: white;
    border: 1px solid transparent;
    padding: 6px 16px;
    background-color: #F4B12E;
}

