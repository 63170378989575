/* loadingSpinner.css */
.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    100% {
        transform: rotate(360deg);
    }
}
