.page-item .page-link{
    background-color: white;
    color: #888888;
}

.page-item.active .page-link {
     background-color: #F4B12E !important;
     color: white !important;
    border: 0.5px solid #888888;
    z-index: 3;
}