.bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95vh;
    background-color: white;
}

.title {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 46px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: aqua;
}

.login-button {
    width: 200px;
    height: 32px;
}