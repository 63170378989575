
.moveItem{
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-bottom : 1px solid #fff;
}
.moveItem:hover{
    border-bottom : 1px solid #a3a198;
}

