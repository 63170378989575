* {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

html {
    overflow: hidden;
    height: 100%;
}

body {
    overflow: auto;
    height: 100%;
}
