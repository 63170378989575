.btn-primary {
    padding: 8px 16px;
    color: white;
    background-color: black;
    /*border: 2px solid #DFDFE6;*/
    border-radius: 8px;
}

.btn-secondary {
    padding: 8px 16px;
    color: white;
    background-color: black;
    border: 2px solid #DFDFE6;
    border-radius: 8px;
}

.dropdown-primary {
    background-color: white;
}