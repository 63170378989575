.main-layout {
    display: flex;
    flex-direction: row;
}

.page-layout {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: #F5F5F9;
    padding-top: 56px;
}

@media screen and (min-width: 1280px) {
    .page-side-padding {
        margin-left: 280px;
    }
}